import PMI from "@screencloud/postmessage-interface";
import { useEffect } from "react";
import { isControlledFrameAvailable } from "../util/iwa";

export const useExposeInterface = () => {
  useEffect(() => {
    let exposedInterface: PMI.ExposedInterface | null = null;

    if (isControlledFrameAvailable()) {
      exposedInterface = new PMI.ExposedInterface({
        screenshot: (payload) => {
          console.log("IWA will take screenshots", payload);
          alert("IWA will take screenshots");
        },
      });
    }

    return () => {
      exposedInterface?.dispose();
    };
  }, []);
};
