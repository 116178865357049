import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { App } from "./pages/App";
import { Debug } from "./pages/Debug";
import { shouldShowConsoleDebugger } from "./util/console-debugger";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/debug",
    element: <Debug />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    {shouldShowConsoleDebugger && (
      <>
        <a className="absolute bottom-0 z-10" href="/debug">
          DEBUG
        </a>
        <button
          className="absolute bottom-0 right-0 z-20"
          type="button"
          onClick={() => window.location.reload()}
        >
          RELOAD
        </button>
      </>
    )}
    <RouterProvider router={router} />
  </React.StrictMode>,
);
