import {
  getDeviceHostName,
  getDeviceSerialNumber,
  getDirectoryDeviceId,
  getSystemInfo,
  ping,
  requestKeepAwake,
  restart,
} from "@repo/chrome-player-message-interface/api";
import { useEffect } from "react";
import { ConsoleDebugger } from "../components/console-debugger";

export const Debug = () => {
  useEffect(() => {
    const tryKeepAwake = async () => {
      const result = await requestKeepAwake();
      console.log("requestKeepAwake:", result);
    };

    tryKeepAwake();
  }, []);

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
      <div className="rounded-lg bg-white p-6 shadow-md">
        <div className="grid grid-cols-2 gap-4">
          <button
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            onClick={async () =>
              console.log(
                "[debug] getDeviceSerialNumber:",
                await getDeviceSerialNumber(),
              )
            }
          >
            getDeviceSerialNumber
          </button>
          <button
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            onClick={async () =>
              console.log("[debug] getSystemInfo:", await getSystemInfo())
            }
          >
            getSystemInfo
          </button>
          <button
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            onClick={async () =>
              console.log(
                "[debug] getDirectoryDeviceId:",
                await getDirectoryDeviceId(),
              )
            }
          >
            getDirectoryDeviceId
          </button>
          <button
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            onClick={async () =>
              console.log(
                "[debug] getDeviceHostName:",
                await getDeviceHostName(),
              )
            }
          >
            getDeviceHostName
          </button>
          <button
            className="col-span-2 rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
            onClick={async () =>
              console.log("[debug] restart:", await restart())
            }
          >
            chrome.restart
          </button>
          <button
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            onClick={async () => console.log("[debug] ping:", await ping())}
          >
            ping
          </button>
          <button
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            onClick={async () =>
              console.log("requestKeepAwake:", await requestKeepAwake())
            }
          >
            requestKeepAwake
          </button>
        </div>
      </div>
      <div className="h-64"></div>
      <ConsoleDebugger visible />
    </div>
  );
};
