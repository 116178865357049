import { useEffect } from "react";
import { useControlledFrame } from "../hooks/useControlledFrame";
import { useExposeInterface } from "../hooks/useExposeInterface";
import { initializeServiceWorkerHandlers } from "../service-worker/service-worker-client";

export const App = () => {
  const { element } = useControlledFrame();
  useExposeInterface();

  useEffect(() => {
    initializeServiceWorkerHandlers();
  }, []);

  return element;
};
