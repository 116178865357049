import { useEffect, useRef, useState } from "react";
import { isControlledFrameAvailable } from "../util/iwa";

/**
 * Custom hook for managing a controlled frame.
 *
 * @description
 * It handles the lifecycle of the frame, sets up communication via postMessage,
 * and handles frame crashes or unresponsiveness.
 *
 * This hook renders `<controlledframe>`
 * or `<iframe>` based on the availability of the controlled frame (IWA) feature.
 */
export const useControlledFrame = () => {
  const ref = useRef<HTMLControlledFrameElement>(null);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // nonce is used to force resurrection of the controlledframe
  const [nonce, setNonce] = useState(new Date().getTime());

  const clearPingInterval = () => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = null;
    }
  };

  useEffect(() => {
    if (!ref.current) return;

    const controlledframe = ref.current;

    controlledframe.addEventListener("contentload", () => {
      pingIntervalRef.current = setInterval(() => {
        const channel = new MessageChannel();

        channel.port2.onmessage = (event) => {
          if (event.data === "iwa:pong") {
            if (pingIntervalRef.current) {
              clearInterval(pingIntervalRef.current);
            }
          }
        };

        controlledframe.contentWindow.postMessage("iwa:ping", "*", [
          channel.port1,
        ]);
      }, 500);

      setTimeout(() => {
        clearPingInterval();
      }, 10000);
    });

    controlledframe.addEventListener("exit", (event) => {
      // Force resurrection of the controlledframe by changing the key (nonce)
      if (event.reason === "crashed") {
        setNonce(new Date().getTime());
      }
    });

    controlledframe.addEventListener("unresponsive", () => {
      // Force resurrection of the controlledframe by changing the key (nonce)
      setNonce(new Date().getTime());
    });

    return () => {
      clearPingInterval();
    };
  }, [nonce]);

  return {
    ref: isControlledFrameAvailable() ? ref : null,
    element: isControlledFrameAvailable() ? (
      <controlledframe
        className="h-full w-full"
        key={nonce}
        src={process.env.VITE_CHROME_PLAYER_URL}
        ref={ref}
      />
    ) : (
      <iframe
        className="h-full w-full"
        src={process.env.VITE_CHROME_PLAYER_URL}
      />
    ),
  };
};
