import { createServiceWorkerClient } from "@repo/serwist/client";
import { swUrl } from "virtual:serwist";

let url: string | TrustedScriptURL = swUrl;

if (window.trustedTypes?.createPolicy) {
  const ttPolicy = window.trustedTypes.createPolicy("ttPolicy", {
    createScriptURL: (string) => string,
  });

  url = ttPolicy.createScriptURL(swUrl) as unknown as string;
}

export const { initializeServiceWorkerHandlers } = createServiceWorkerClient({
  url,
});
