import { handleOverTheAirUpdate } from "@repo/shared/service-worker";
import { Serwist } from "@serwist/window";

export interface ServiceWorkerClientOptions {
  url: string;
}

let isReloading = false;
let isInitialized = false;

const reload = () => {
  if (isReloading) return;
  isReloading = true;
  window.location.reload();
};

export const createServiceWorkerClient = (
  options: ServiceWorkerClientOptions,
) => {
  const initializeServiceWorkerHandlers = async () => {
    const serwist = new Serwist(options.url);

    const isSerwistAvailable = "serviceWorker" in navigator && serwist;
    if (!isSerwistAvailable) {
      console.log("[service-worker-client] serwist not available");
      return;
    }

    if (isInitialized) return;
    isInitialized = true;

    navigator.serviceWorker.addEventListener("controllerchange", reload);

    navigator.serviceWorker.addEventListener("message", (event) => {
      const data = event.data;

      if (data.action === "reload") {
        reload();
      }
    });

    await serwist?.register();

    handleOverTheAirUpdate();
  };

  return {
    initializeServiceWorkerHandlers,
  };
};
