import { callPMIToWebFrame } from "../pmi";

interface Action {
  action: "reload" | "skip_waiting";
}

export type ServiceWorkerMessage = Action;

// ref: https://whatwebcando.today/articles/handling-service-worker-updates/
export const handleOverTheAirUpdate = async () => {
  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) return;

  // detect Service Worker update available and wait for it to become installed
  registration.addEventListener("updatefound", () => {
    if (registration.installing) {
      // wait until the new Service worker is actually installed (ready to take over)
      registration.installing.addEventListener("statechange", () => {
        const message: ServiceWorkerMessage = { action: "skip_waiting" };
        registration.waiting?.postMessage(message);
      });
    }
  });

  const ONE_HOUR = 60 * 60 * 1000;
  registration.update();
  setInterval(() => registration.update(), ONE_HOUR);
};

export const unregisterServiceWorker = async () => {
  if (!navigator.serviceWorker) return;

  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) return;

  await registration.unregister();
};

export const purgeCaches = async () => {
  const keys = await window.caches.keys();
  for (const key of keys) {
    await window.caches.delete(key);
  }
};

export const clearStudioPlayerServiceWorkerCached = () =>
  callPMIToWebFrame("clearServiceWorkerCached");
