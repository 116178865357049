import { ResponseMessagePayload, SendMessagePayload, SystemInfo } from ".";

const CHROME_PLAYER_ENTERPRISE_EXTENSION_ID =
  "jnmaledhgpoamilcocooopoecloipihf";

const isSkipExtension = localStorage.getItem(
  "__sc_player_skip_extension_result",
);

const callExtensionAPI = <T>(
  message: SendMessagePayload,
): Promise<T | null> => {
  if (isSkipExtension) {
    return Promise.resolve(null);
  }

  return new Promise((resolve, reject) => {
    if (!chrome.runtime?.sendMessage)
      return reject(
        new Error(
          "chrome.runtime is not available (Missing Chrome enterprise extension)",
        ),
      );

    chrome.runtime.sendMessage(
      CHROME_PLAYER_ENTERPRISE_EXTENSION_ID,
      message,
      (response: ResponseMessagePayload<T>) => {
        if (response.error) {
          reject(new Error(response.error));
        }
        resolve(response.result);
      },
    );
  });
};

export const getSystemInfo = (): Promise<SystemInfo> => {
  return new Promise((resolve) => {
    const info = {} as SystemInfo;
    const promises: Promise<void>[] = [];
    const lookup: Record<keyof SystemInfo, () => Promise<any>> = {
      platform: () =>
        callExtensionAPI({
          action: "callChromeCallbackAPI",
          method: "runtime.getPlatformInfo",
        }),
      cpu: () =>
        callExtensionAPI({
          action: "callChromeCallbackAPI",
          method: "system.cpu.getInfo",
        }),
      display: () =>
        callExtensionAPI({
          action: "callChromeCallbackAPI",
          method: "system.display.getInfo",
        }),
      memory: () =>
        callExtensionAPI({
          action: "callChromeCallbackAPI",
          method: "system.memory.getInfo",
        }),
      storage: () =>
        callExtensionAPI({
          action: "callChromeCallbackAPI",
          method: "system.storage.getInfo",
        }),
      network: () =>
        callExtensionAPI({
          action: "callChromeCallbackAPI",
          method: "enterprise.networkingAttributes.getNetworkDetails",
        }),
      hostname: () =>
        callExtensionAPI({
          action: "callChromeCallbackAPI",
          method: "enterprise.deviceAttributes.getDeviceHostname",
        }),
      hardwarePlatform: getHardwarePlatformInfo,
    };

    Object.keys(lookup).forEach((key) => {
      promises.push(
        new Promise<void>((resolve) => {
          const systemInfoGetter = lookup[key as keyof SystemInfo];
          if (systemInfoGetter) {
            systemInfoGetter()
              .then((val) => {
                info[key as keyof SystemInfo] = val;
                resolve();
              })
              .catch((e) => {
                console.error(`Getter function error for key: ${key}`, e);
                resolve();
              });
          } else {
            console.error(`No getter function available for key: ${key}`);
            resolve();
          }
        }),
      );
    });

    Promise.all(promises).then(() => resolve(info));
  });
};

export const getDirectoryDeviceId = () => {
  return callExtensionAPI<string>({
    action: "callChromeCallbackAPI",
    method: "enterprise.deviceAttributes.getDirectoryDeviceId",
  });
};

export const getDeviceHostName = () => {
  return callExtensionAPI({
    action: "callChromeCallbackAPI",
    method: "enterprise.deviceAttributes.getDeviceHostname",
  });
};

export const getDeviceSerialNumber = () => {
  return callExtensionAPI<string>({
    action: "callChromeCallbackAPI",
    method: "enterprise.deviceAttributes.getDeviceSerialNumber",
  });
};

export const restart = (): Promise<void> => {
  // NOTE: detect if it is kiosk, or else call runtime.reload
  return callExtensionAPI({
    action: "callChromeAPI",
    method: "runtime.restart",
  }) as Promise<void>;
};

export const ping = () => {
  return callExtensionAPI({
    action: "ping",
  });
};

export const requestKeepAwake = () => {
  return callExtensionAPI({
    action: "callChromeAPI",
    method: "power.requestKeepAwake",
    args: ["display"],
  });
};

export const screenshot = (): Promise<string | null> => {
  return callExtensionAPI<string>({
    action: "screenshot",
  });
};

export const getAuthToken = () => {
  return callExtensionAPI({
    action: "callChromeCallbackAPI",
    method: "identity.getAuthToken",
  });
};

export const getProfileUserInfo = () => {
  return callExtensionAPI({
    action: "callChromeCallbackAPI",
    method: "identity.getProfileUserInfo",
  });
};

/**
 * @requires EnterpriseHardwarePlatformAPIEnabled
 * @url https://chromeenterprise.google/policies/#EnterpriseHardwarePlatformAPIEnabled
 */
export const getHardwarePlatformInfo = async () => {
  try {
    const hardwarePlatformInfo = await callExtensionAPI<
      SystemInfo["hardwarePlatform"]
    >({
      action: "callChromeCallbackAPI",
      method: "enterprise.hardwarePlatform.getHardwarePlatformInfo",
    });

    return hardwarePlatformInfo;
  } catch (e) {
    console.warn("Failed to get hardware platform info", e);
  }
};

export const getManifest = () => {
  return callExtensionAPI<chrome.runtime.Manifest>({
    action: "callChromeAPI",
    method: "runtime.getManifest",
  });
};

export const setOutputMute = (mute: boolean) => {
  return callExtensionAPI({
    action: "callChromeAPI",
    method: "audio.setMute",
    args: ["OUTPUT", mute],
  });
};

export const isOutputMute = () => {
  return callExtensionAPI<boolean>({
    action: "callChromeAPI",
    method: "audio.getMute",
    args: ["OUTPUT"],
  });
};
