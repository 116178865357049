/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useRef, useState } from "react";
import { shouldShowConsoleDebugger } from "../util/console-debugger";

interface LogEntry {
  type: "log" | "error" | "warn" | "info";
  message: any;
  timestamp: Date;
}

const formatTimestamp = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

interface ConsoleDebuggerProps {
  visible?: boolean;
}
export const ConsoleDebugger: React.FC<ConsoleDebuggerProps> = (props) => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [isVisible, setIsVisible] = useState(props.visible ?? false);
  const logContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const logContainer = logContainerRef.current;

    const originalConsoleLog = console.log;
    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;
    const originalConsoleInfo = console.info;

    const logInterceptor = (
      type: "log" | "error" | "warn" | "info",
      ...args: any[]
    ) => {
      if (isVisible) {
        const message = args
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .map((arg) => (typeof arg === "object" ? JSON.stringify(arg) : arg))
          .join(" ");
        setLogs((prevLogs) => {
          const newLogs = [
            ...prevLogs,
            { type, message, timestamp: new Date() },
          ];
          if (logContainer) {
            logContainer.scrollTop = logContainer.scrollHeight;
          }
          return newLogs;
        });
      }
    };

    console.log = (...args: any[]) => {
      logInterceptor("log", ...args);
      originalConsoleLog(...args);
    };

    console.error = (...args: any[]) => {
      logInterceptor("error", ...args);
      originalConsoleError(...args);
    };

    console.warn = (...args: any[]) => {
      logInterceptor("warn", ...args);
      originalConsoleWarn(...args);
    };

    console.info = (...args: any[]) => {
      logInterceptor("info", ...args);
      originalConsoleInfo(...args);
    };

    return () => {
      console.log = originalConsoleLog;
      console.error = originalConsoleError;
      console.warn = originalConsoleWarn;
      console.info = originalConsoleInfo;
    };
  }, [isVisible]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const clearLogs = () => {
    setLogs([]);
  };

  return (
    <div className="fixed inset-x-0 bottom-0 m-4">
      <div className="rounded-lg bg-gray-800 text-white shadow-lg">
        <div className="flex items-center justify-between px-4 py-2">
          <h2 className="text-lg font-semibold">Debugger Tool</h2>
          <div>
            <button
              className="mx-2 rounded bg-gray-600 px-2 py-1 text-sm hover:bg-gray-500"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"}
            </button>
            <button
              className="mx-2 rounded bg-red-600 px-2 py-1 text-sm hover:bg-red-500"
              onClick={clearLogs}
            >
              Clear
            </button>
            {shouldShowConsoleDebugger && <a href="/">Back to Player</a>}
          </div>
        </div>
        {isVisible && (
          <div
            className="max-h-96 overflow-auto px-4 py-2"
            ref={logContainerRef}
          >
            {logs.map((log, index) => (
              <div
                key={index}
                className={`my-1 rounded px-2 py-1 ${
                  log.type === "error"
                    ? "bg-red-700"
                    : log.type === "warn"
                      ? "bg-yellow-700"
                      : log.type === "info"
                        ? "bg-blue-700"
                        : "bg-gray-700"
                }`}
              >
                <span className="text-xs text-gray-400">
                  {formatTimestamp(log.timestamp)}
                </span>
                <span className="ml-2">{log.message}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
